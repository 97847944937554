import React, { useState, useEffect, useCallback } from 'react';
import { PlusCircle, MinusCircle } from 'lucide-react';

const ZinguerieCalculator = () => {
  const [pans, setPans] = useState([]);
  const [totalSummary, setTotalSummary] = useState({});

  const addPan = () => {
    setPans([...pans, {
      sections: [{
        gouttiereLength: '',
        faitageHeight: ''
      }],
      descentesCount: '',
      basePenteHeight: ''
    }]);
  };

  const removePan = (panIndex) => {
    const newPans = [...pans];
    newPans.splice(panIndex, 1);
    setPans(newPans);
  };

  const addSection = (panIndex) => {
    const newPans = [...pans];
    newPans[panIndex].sections.push({
      gouttiereLength: '',
      faitageHeight: ''
    });
    setPans(newPans);
  };

  const removeSection = (panIndex, sectionIndex) => {
    const newPans = [...pans];
    newPans[panIndex].sections.splice(sectionIndex, 1);
    setPans(newPans);
  };

  const updatePan = (panIndex, field, value) => {
    const newPans = [...pans];
    newPans[panIndex][field] = value;
    setPans(newPans);
  };

  const updateSection = (panIndex, sectionIndex, field, value) => {
    const newPans = [...pans];
    newPans[panIndex].sections[sectionIndex][field] = value;
    setPans(newPans);
  };

  const isValidNumber = (value) => {
    return value !== '' && !isNaN(parseFloat(value)) && isFinite(value);
  };

  const calculatePan = useCallback((pan) => {
    const { sections, descentesCount, basePenteHeight } = pan;
    
    let totalGouttiereLength = 0;
    let totalSurfacePan = 0;

    const hasValidGouttiereLength = sections.some(section => isValidNumber(section.gouttiereLength));
    const hasValidDescentesCount = isValidNumber(descentesCount);
    const hasValidBasePenteHeight = isValidNumber(basePenteHeight);

    if (!hasValidGouttiereLength && !hasValidDescentesCount && !hasValidBasePenteHeight) {
      return null;
    }

    for (const section of sections) {
      if (isValidNumber(section.gouttiereLength)) {
        const sectionGouttiereLength = parseFloat(section.gouttiereLength);
        const sectionFaitageHeight = isValidNumber(section.faitageHeight) ? parseFloat(section.faitageHeight) : 0;
        
        totalGouttiereLength += sectionGouttiereLength;
        totalSurfacePan += sectionGouttiereLength * sectionFaitageHeight;
      }
    }

    const descentesNum = hasValidDescentesCount ? parseInt(descentesCount, 10) : 0;
    const basePenteNum = hasValidBasePenteHeight ? parseFloat(basePenteHeight) : 0;

    const result = {};

    if (hasValidGouttiereLength) {
      result["Longueur totale de gouttière (m)"] = totalGouttiereLength.toFixed(2);
      result["Nombre de dalles"] = Math.ceil(totalGouttiereLength / 3.90);
      result["Nombre de fonds"] = 2;
      result["Nombre de crochets"] = Math.ceil(totalGouttiereLength * 2) + 1;
      result["Nombre de soudures"] = result["Nombre de dalles"] - 1 + (totalGouttiereLength > 12 ? 1 : 0);
      result["Nombre de joints de dilatation"] = totalGouttiereLength > 12 ? 1 : 0;
      result["Surface du pan (m²)"] = totalSurfacePan.toFixed(2);
    }

    if (hasValidDescentesCount) {
      result["Nombre de naissances"] = descentesNum;
      result["Nombre de coudes"] = 2 * descentesNum;
    }

    if (hasValidBasePenteHeight) {
      const tronconsCount = Math.ceil(basePenteNum / 1.90);
      result["Nombre de tronçons"] = tronconsCount * descentesNum;
      result["Nombre de fixations"] = (tronconsCount + 1) * descentesNum;
      result["Nombre de bagues"] = (tronconsCount + 1) * descentesNum;
    }

    return result;
  }, []);  // Utiliser useCallback pour mémoriser la fonction et éviter sa recréation à chaque re-render.

  useEffect(() => {
    const newTotalSummary = pans.reduce((acc, pan) => {
      const panSummary = calculatePan(pan);
      if (panSummary) {
        Object.entries(panSummary).forEach(([key, value]) => {
          if (typeof value === 'string' && value.includes('.')) {
            acc[key] = ((parseFloat(acc[key]) || 0) + parseFloat(value)).toFixed(2);
          } else {
            acc[key] = (acc[key] || 0) + value;
          }
        });
      }
      return acc;
    }, {});
    setTotalSummary(newTotalSummary);
  }, [pans, calculatePan]);  // Ajouter calculatePan comme dépendance.

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Calculateur de Zinguerie</h1>
      
      <div className="border p-4 mb-4">
        <h2 className="text-xl font-semibold mb-2">Saisie des pans</h2>
        {pans.map((pan, panIndex) => (
          <div key={panIndex} className="mb-4 p-2 border rounded">
            <h3 className="font-semibold">Pan {panIndex + 1}</h3>
            {pan.sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="grid grid-cols-2 gap-2 mb-2">
                <input
                  type="number"
                  placeholder="Longueur de gouttière (m)"
                  value={section.gouttiereLength}
                  onChange={(e) => updateSection(panIndex, sectionIndex, 'gouttiereLength', e.target.value)}
                  className="border p-1"
                />
                <input
                  type="number"
                  placeholder="Hauteur au faîtage (m)"
                  value={section.faitageHeight}
                  onChange={(e) => updateSection(panIndex, sectionIndex, 'faitageHeight', e.target.value)}
                  className="border p-1"
                />
                {pan.sections.length > 1 && (
                  <button onClick={() => removeSection(panIndex, sectionIndex)} className="text-red-500">
                    <MinusCircle /> Supprimer section
                  </button>
                )}
              </div>
            ))}
            <button onClick={() => addSection(panIndex)} className="bg-blue-500 text-white px-2 py-1 rounded mb-2">
              <PlusCircle className="inline mr-1" /> Ajouter une section
            </button>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="number"
                placeholder="Nombre de descentes"
                value={pan.descentesCount}
                onChange={(e) => updatePan(panIndex, 'descentesCount', e.target.value)}
                className="border p-1"
              />
              <input
                type="number"
                placeholder="Hauteur bas de pente (m)"
                value={pan.basePenteHeight}
                onChange={(e) => updatePan(panIndex, 'basePenteHeight', e.target.value)}
                className="border p-1"
              />
            </div>
            <button onClick={() => removePan(panIndex)} className="text-red-500 mt-2">
              <MinusCircle /> Supprimer pan
            </button>
          </div>
        ))}
        <button onClick={addPan} className="bg-green-500 text-white px-4 py-2 rounded flex items-center">
          <PlusCircle className="mr-2" /> Ajouter un pan
        </button>
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="border p-4">
          <h2 className="text-xl font-semibold mb-2">Résultats par pan</h2>
          {pans.map((pan, index) => {
            const summary = calculatePan(pan);
            return summary ? (
              <div key={index} className="mb-2">
                <h3 className="font-semibold">Résumé Pan {index + 1}</h3>
                <ul>
                  {Object.entries(summary).map(([key, value]) => (
                    <li key={key}>{key}: {value}</li>
                  ))}
                </ul>
              </div>
            ) : null;
          })}
        </div>
        
        <div className="border p-4">
          <h2 className="text-xl font-semibold mb-2">Récapitulatif total</h2>
          {Object.keys(totalSummary).length > 0 ? (
            <ul>
              {Object.entries(totalSummary).map(([key, value]) => (
                <li key={key}>{key}: {value}</li>
              ))}
            </ul>
          ) : (
            <p>Remplissez au moins un champ pour voir le récapitulatif.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZinguerieCalculator;

