import React from 'react';
   import ZinguerieCalculator from './ZinguerieCalculator';
   import './App.css';

   function App() {
     return (
       <div className="App">
         <ZinguerieCalculator />
       </div>
     );
   }

   export default App;